<template>
  <v-main style="min-width: auto !important;">
    <v-container fluid style="max-width: 1280px" class="pa-0 mt-8">
      <v-row no-gutters class="mb-4 mb-md-16">
        <v-col cols="12" md="auto">
            <v-card
              class="py-0 mr-md-14 px-4 px-md-0"
              elevation="0"
              :max-width="smAndDown ? '100%' : 343"
              contain
            >
              <v-img :src="getImageUrl"
                     contain
                     min-height="60"
                     max-height="292"
                     class="d-flex align-end rounded mb-4"
                     :key="getBookshelfAssessmentId"
              >

                <div class="img-progress-mask" :style="progressMaskWidth"></div>
                <v-row dense no-gutters style="position: relative;z-index: 2">
                  <v-col cols="auto" class="mr-2">
                    <v-card color="white" width="72" height="70" class="pa-3 mb-2 ml-2">
                      <v-img alt="まとめて査定！バリューブックスの本棚スキャン" src="/assets/images/estimate/title_bookshelf_icon.svg" />
                    </v-card>
                  </v-col>
                  <v-col class="align-self-end">
                    <v-chip color="black" text-color="white" style="width: calc(100% - 4px); height: 21px" class="text-caption justify-center mb-2">
                      <span v-if="getOverallProgress !== 100">{{getOverallProgress}}% 査定中</span>
                      <span v-else>スキャン完了</span>
                    </v-chip>
                  </v-col>
                </v-row>
              </v-img>
              <template v-if="!smAndDown">
                <v-row dense no-gutters class="mb-2 pb-2" style="border-bottom: 1px dotted #2C2C2C">
                  <v-col class="text-body-2">
                    合計<span class="text-h4 font-weight-bold px-1">{{ getScanResult.length }}</span>冊
                  </v-col>
                  <v-col class="text-body-2 text-right">
                    <span class="text-h4 font-weight-bold px-1">{{ totalAppraisalPrice }}</span>円
                  </v-col>
                </v-row>
                <v-card-text class="pa-0">
                  <p class="pl-0 text-caption mb-0">※実際の買取価格は本の状態や時期によって変動します。</p>
                  <p class="pl-0 text-caption">※本が比較的きれいな状態の価格を表示しています。</p>
                </v-card-text>
                <v-card-actions v-if="getScanResult && getScanResult.length > 0">
                  <v-btn color="#00475A" max-width="262" width="100%" class="mx-auto" elevation="0" @click="getIsItemSelectable = !getIsItemSelectable">
                    <v-icon v-if="!getIsItemSelectable" color="white" small class="mr-1">mdi-tray-arrow-down</v-icon><span class="white--text" style="letter-spacing: 0;">{{ !getIsItemSelectable ? 'スキャン結果をライブラリに保存' : 'キャンセル' }}</span>
                  </v-btn>
                </v-card-actions>
              </template>
            </v-card>
        </v-col>
        <v-col class="d-flex flex-wrap">
          <v-alert v-if="!getIsItemSelectable" color="#E7760D" class="white--text text-caption text-md-body-2 text-md-center py-2 mx-auto" :style="smAndDown ? 'flex: 0 0 calc(100% - 32px)' : 'flex: 0 0 100%'" height="fit-content">
            <v-icon color="white" small class="mr-1">mdi-alert</v-icon><strong>結果が正確ではないことがあります。スキャン内容が本棚と一致しているかご確認ください。</strong>
            <p class="pl-0 mb-0 text-md-caption" :style="smAndDown ? 'font-size: 10px;':null">より正確なスキャンなら、
              <v-btn v-if="smAndDown" text class="pa-0 ma-0 mt-md-n1" tag="label" for="take-picture-button" height="auto" style="cursor: pointer">
                <span class="white--text text-decoration-underline" style="font-size: 10px; text-decoration-color: white">バーコードスキャン</span>
            </v-btn><template v-else>バーコードスキャン</template>がおすすめ！</p>
          </v-alert>
          <v-row v-else dense no-gutters class="mt-0 mb-6 text-center" style="width:100%;">
            <v-col cols="12" class="pa-0 d-flex justify-center align-self-center caption">
              <p class="pr-0 mb-0 text-body-2 text-md-body-1 font-weight-bold">
                ライブラリに追加する本を選んでください</p>
            </v-col>
            <v-col cols="12" class="pa-0 d-flex justify-center align-self-center caption">
              <v-btn-toggle v-model="isAllToggle">
                <v-btn outlined class="text-caption" style="border-color:#2c2c2c !important;" height="32"
                       :value="true" v-if="!getIsAllSelected"><span class="font-weight-bold">すべてを選択</span>
                </v-btn>
                <v-btn outlined class="text-caption" style="border-color:#2c2c2c !important;" height="32"
                       :value="false" v-if="getIsAllSelected"><span class="font-weight-bold">すべてを選択解除</span>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-skeleton-loader
            v-if="!getScanResult || getScanResult.length === 0"
            class="loader-scan pa-0 rounded-0 d-flex flex-wrap justify-center"
            type="image@21"
          ></v-skeleton-loader>
          <v-item-group multiple v-model="selectedItemsIds" v-if="getScanResult && getScanResult.length > 0" class="d-flex flex-wrap justify-center">
            <v-card
              flat
              class="d-flex flex-column mx-2 mb-8"
              v-for="(scanItem,index) in getScanResult"
              :key="scanItem.id"
              width="80"
            >
              <v-item v-slot="{ active, toggle }"
                      :key="scanItem.library_item_id ? scanItem.vs_catalog_id: scanItem.asin + '_' + index"
                      :value="scanItem.vs_catalog_id ? scanItem.vs_catalog_id: scanItem.asin"
              >
                <span
                  @click="toggle"
                  :style="getIsItemSelectable? 'cursor: pointer;' :''"
                >
                  <vb-item
                    :active="active"
                    :item="scanItem"
                    :is_item_selectable="getIsItemSelectable"
                    :visible_add-cart_btn="false"
                    layout="fixed-column"
                    fixed_width="80"
                    hide_title
                    class="scan-item"
                    style="margin: 0 0 16px !important;"></vb-item>
                </span>
              </v-item>
              <v-card-title class="text-caption pa-0">{{ scanItem.title }}</v-card-title>
              <v-card-text class="text-caption pa-0 mt-auto d-flex flex-column">参考価格<v-chip label color="#0095d9" text-color="white" x-small class="px-2" style="max-width: fit-content">{{ scanItem.appraisal_price }}円</v-chip></v-card-text>
            </v-card>
            <v-card
              class="mx-2 mb-0"
              v-for="i in 9"
              :key="`empty-${i}`"
              width="80"
              height="0"
            />
          </v-item-group>
        </v-col>
      </v-row>

      <template v-if="smAndDown">
        <v-row dense no-gutters class="mb-2 pb-2 mx-8" style="border-bottom: 1px dotted #2C2C2C">
          <v-col class="text-body-2">
            合計<span class="text-h4 font-weight-bold px-1">{{ getScanResult.length }}</span>冊
          </v-col>
          <v-col class="text-body-2 text-right">
            <span class="text-h4 font-weight-bold px-1">{{ totalAppraisalPrice }}</span>円
          </v-col>
        </v-row>
        <v-card-text class="pa-0 mx-0 px-8">
          <p class="pl-0 text-caption mb-0">※実際の買取価格は本の状態や時期によって変動します。</p>
          <p class="pl-0 text-caption">※本が比較的きれいな状態の価格を表示しています。</p>
        </v-card-text>
        <v-card-actions class="pt-6 pb-10" v-if="getScanResult && getScanResult.length > 0">
          <v-btn color="#00475A" max-width="262" width="100%" class="mx-auto" elevation="0" @click="getIsItemSelectable = !getIsItemSelectable">
            <v-icon v-if="!getIsItemSelectable" color="white" small class="mr-1">mdi-tray-arrow-down</v-icon><span class="white--text" style="letter-spacing: 0;">{{ !getIsItemSelectable ? 'スキャン結果をライブラリに保存' : 'キャンセル' }}</span>
          </v-btn>
        </v-card-actions>
      </template>

      <v-card flat class="d-md-block d-none text-center mb-16">
        <v-img src="/assets/images/estimate/btn_balloon.svg" alt="本の背表紙写真があればパソコンからでも査定できます" max-width="288" class="mx-auto mb-1" />
        <v-card-actions class="pa-0">
          <v-btn color="#2c2c2c" class="mx-auto" elevation="0">
            <label for="bookshelf-image-file-input" style="cursor: pointer"><v-icon color="white" small class="mr-1">mdi-camera</v-icon><span class="white--text">自分の本棚をスキャンしてみる</span></label>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>

    <v-card flat tile class="d-flex mb-md-14 flex-column align-center py-14" color="#eeeadb">
      <v-card-title class="pa-0 px-4 mb-8">他の本棚の買取価格をチェックする</v-card-title>
      <v-row no-gutters class="justify-md-center">
        <v-col v-for="(item, index) in scanSamples" :key="index" cols="6" md="auto" class="mb-2">
          <v-card flat color="transparent" class="mx-4 d-flex flex-column align-center text-center" @click="execSampleBookshelfScan(item.type)">
            <v-img
              :src="'/assets/images/index/scan_sample/' + (smAndDown ? 'sp' : 'pc') + '_scan-photo_' + item.type + '.jpg'"
              :alt="item.name + 'の本棚'"
              width="100%"
              max-width="150"
              height="70"
              class="rounded"
              style="border: 1px solid #2c2c2c;"/>
            <v-img class="mt-n5 mb-3" :src="'/assets/images/common/users/icon_' + item.type + '.png'" width="40" height="40" :alt="item.name" />
            <v-card-title class="pa-0 text-caption">{{ item.character }}</v-card-title>
            <v-card-text class="pa-0 text-center" style="font-size: 11px">{{ item.name }}（{{ item.age }}）の本棚</v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-card-actions class="pa-0 mt-8" v-if="smAndDown">
          <v-btn color="#2c2c2c" class="mx-auto" elevation="0">
            <label for="bookshelf-image-file-input"><v-icon color="white" small class="mr-1">mdi-camera</v-icon><span class="white--text">自分の本棚をスキャンしてみる</span></label>
          </v-btn>
      </v-card-actions>
    </v-card>

    <v-row class="d-flex py-14 px-4 pr-md-11 pl-md-16 mb-md-16 mx-auto" no-gutters style="max-width: 1024px; background-color: #fbfaf5">
      <v-col cols="12" md="auto" class="mr-md-16 mb-6 mb-md-0">
        <v-card flat color="transparent">
          <v-img class="mb-8 mx-auto mx-md-0" src="/assets/images/estimate/banner_library_title.svg" alt="買取価格が分かるだけじゃない！" width="240" />
          <v-card-text class="py-0 px-5 px-md-0 text-body-1 text-md-h6" style="color: #00475a;">
            本棚にある本をまとめて<br>
            オンライン上に<br class="d-none d-md-block">管理することができます
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          flat
          class="py-8 py-md-9 pl-5 pl-md-10"
          color="transparent"
          style="border: 2px solid #1b4658 !important;"
          :style="smAndDown ? 'background-size: 31vw auto; background-position: right 8px bottom;' : 'background-size: 300px 180px; background-position: right 32px bottom;'"
          :img="smAndDown ? 'https://wcdn.valuebooks.jp/ws/site/assets/images/estimate/sp_image_library.png' : 'https://wcdn.valuebooks.jp/ws/site/assets/images/estimate/pc_image_library.png'"
        >
          <v-img src="/assets/images/estimate/title_library.svg" alt="ライブラリ" width="172" class="mb-4" />
          <v-card-text class="pa-0 mb-6 text-caption" style="color: #00475a;">
            売った本も、読んだ本も、<br>
            これから読みたい本も、<br>
            まとめて管理。<br>
            あなただけのオンライン本棚。
          </v-card-text>
          <v-btn elevation="0" color="#00475a" href="/library"><span class="text-caption white--text">ライブラリの使い方をみる</span></v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-bottom-navigation
      :input-value="getIsItemSelectable"
      fixed
      horizontal
      :height="$vuetify.breakpoint.mdAndUp ? 80 : 112"
      grow
      color="teal"
      :background-color="$vuetify.breakpoint.mdAndUp ? 'grey lighten-3' : '#ffffff'"
      style="z-index: 5;"
    >
      <v-row class="ma-0">
        <v-col class="col-8 d-flex pa-0 mx-0" v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn v-if="$vuetify.breakpoint.mdAndUp" @click="closeBottomNavigation" class="mr-auto text--grey-darken-4"
                 height="100%">
            <span>キャンセル</span>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col class="col-md-1 col-6 d-flex align-center pa-0 pr-md-4 ml-auto mr-auto mr-md-2 my-0">
          <v-btn @click="openCopyItemDialog" class="text--grey-darken-4 my-auto mx-0 py-4" height="100%"
                 style="max-width:100%;">
            <span>コピー</span>
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </v-col>
        <v-col class="col-12 px-6" v-if="$vuetify.breakpoint.smAndDown">
          <vb-simple-button
            v-if="$vuetify.breakpoint.smAndDown"
            @click="closeBottomNavigation"
            color="#ffffff"
            width="100%"
            height="32px"
            class="grey--text text--darken-4"
            style="font-weight:bold;border: solid 1px #2c2c2c;"
          >閉じる
          </vb-simple-button>
        </v-col>
      </v-row>
    </v-bottom-navigation>
    <add-folder-item v-model="isShowCopyDialog" @showSnackbar="showSnackbar">
      <template #title><span class="pl-0 pt-4 pb-0 text-body-2 font-weight-black">コピーするフォルダを選択してください。</span></template>
      <template #execButtonLabel>OK</template>
    </add-folder-item>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :timeout="snackbar.timeout" bottom right
                rounded="pill">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark small>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>{{ snackbar.text }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-model="noResultsDialog"
      max-width="500"
    >
      <v-card class="pt-6">
        <v-card-text>
          <p>商品が見つかりませんでした。</p>
          <p>本棚画像を変更してお試しください。</p>
          <p>また、本棚スキャンについて詳しくは「<a href="/sell/faq#faq-search">よくある質問</a>」をご覧下さい。</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="noResultsDialog = false"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="backendErrorDialog"
      max-width="500"
    >
      <v-card class="pt-6">
        <v-card-text>
          <p>本棚画像を読み取ることができませんでした。</p>
          <p>もう一度お試しください。</p>
          <p>また、本棚スキャンについて詳しくは「<a href="/sell/faq#faq-search">よくある質問</a>」をご覧下さい。</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="backendErrorDialog = false"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isShowLoginDialog"
      max-width="480"
    >
      <modal-login
        @close="isShowLoginDialog = false"
      >
        <template #closeButton>
          <v-icon @click="isShowLoginDialog = false" color="#2c2c2c" class="d-block ml-auto">mdi-close</v-icon>
        </template>
      </modal-login>
    </v-dialog>
  </v-main>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import VbEstimatePrice from "../atoms/vb-estimate-price.vue";
import VbItem from "../molecules/vb-item.vue";
import VbModal from "../atoms/vb-modal.vue";
import ModalLogin from "../molecules/modal-login.vue";
import VbSimpleButton from "../atoms/vb-simple-button.vue";
import AddFolderItem from "./library/add-folder-item.vue";

export default {
  name: "bookshelf-scan",
  components: {
    AddFolderItem,
    VbSimpleButton, ModalLogin, VbModal,
    VbEstimatePrice,
    VbItem
  },
  data() {
    return {
      getIsItemSelectable: false,
      isShowEditDialog: false,
      isShowCopyDialog: false,
      selectedItemsIdsArr: [],
      snackbar: {
        color: "",
        icon: "",
        text: "",
        timeout: 2000,
        visible: false,
      },
      scanSamples: VueConfig.topScanSamples,
      noResultsDialog: false,
      isShowLoginDialog: false,
    };
  },
  async mounted() {
    // BlobオブジェクトからFileオブジェクトを作成
    const file = await this.createFileObject();

    this.mutateBookshelfScanImageFile(file);
    this.updateBookshelfScanImage(file);
  },
  methods: {
    ...mapActions('bookshelfScan', [
      'updateBookshelfScanImage',
      'createSampleFileObject',
    ]),
    ...mapMutations('bookshelfScan', [
      'mutateBookshelfScanImageFile',
      'resetScanResult',
      'mutateBackendErrorDialog',
    ]),
    ...mapActions('search', [
      'updateSelectedItemsIds',
      'updateIsAllSelected',
    ]),
    closeBottomNavigation() {
      this.updateSelectedItemsIds([]);
      this.getIsItemSelectable = false;
    },
    async openCopyItemDialog() {
      const isLogin = await this.loginStatusCheck();
      if(!isLogin) {
        this.isShowLoginDialog = true;
        return;
      }
      if (this.getSelectedItemsCount > 0) {
        this.isShowCopyDialog = true
      } else {
        this.showSnackbar("not_select_copy")
      }
    },
    selectAllItems() {
      let items = []
      if (this.getIsAllSelected) {
        this.getScanResult.forEach((item, key) => {
          items.push(item.id)
        });
      }
      this.updateSelectedItemsIds(items)
    },
    showSnackbar(type) {
      if (!type) return;
      switch (type) {
        case "add":
          this.snackbar = {
            color: "#009098",
            icon: "mdi-information",
            text: "追加しました",
            timeout: 5000,
            visible: true
          };
          break;
        case "warning":
          this.snackbar = {
            color: "#bf7070",
            icon: "mdi-alert-circle",
            timeout: 5000,
            text: "更新に失敗しました。時間を置いてお試しください。",
            visible: true
          };
          break;
        case "error":
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            timeout: 5000,
            text: "処理に失敗しました。再読み込みしお試しください。",
            visible: true
          };
          break;
        case "not_select_delete":
          this.snackbar = {
            color: "#bf7070",
            icon: "mdi-alert-circle",
            timeout: 5000,
            text: "削除したい本を選んでください",
            visible: true
          };
          break;
        case "not_select_copy":
          this.snackbar = {
            color: "#bf7070",
            icon: "mdi-alert-circle",
            timeout: 3000,
            text: "コピーしたい本を選んでください",
            visible: true
          };
          break;
      }
      this.getIsItemSelectable = false;
    },
    async execSampleBookshelfScan(type) {
      this.resetScanResult();

      const blob = await this.createSampleFileObject(this.scanSamples[type].images.cdn.pc);
      const file = new File([blob], 'filename.png', { type: 'image/png' });
      this.mutateBookshelfScanImageFile(file);
      this.updateBookshelfScanImage(file);
    },
    async createFileObject() {
      return new Promise(async (resolve, reject) => {
        try {
          const base64String = await new Promise(resolve => {
            // localStorageからデータを取得する処理をここに記述
            // 例:
            setTimeout(() => {
              resolve(sessionStorage.getItem('bookshelfScanImage'));
            }, 1000); // 1秒後に取得完了を想定
          });

          // Base64文字列からBlobオブジェクトを作成する処理 (元のコードと同様)
          const byteString = atob(base64String.split(',')[1]);
          const arrayBuffer = new Uint8Array(byteString.length);
          for (let i = 0; i < byteString.length; i++) {
            arrayBuffer[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([arrayBuffer], { type: 'image/png' });

          // BlobオブジェクトからFileオブジェクトを作成
          const file = new File([blob], 'filename.png', { type: 'image/png' });

          resolve(file);
        } catch (error) {
          reject(error);
        }
      });
    },
    loginStatusCheck() {
      return axios.get('/api/account/isLogin')
        .then((response)=>{
          return response.data.status;
        })
    }
  },
  computed: {
    ...mapState('bookshelfScan', [
      'uploadProgress',
    ]),
    ...mapGetters('bookshelfScan', [
      'getImageUrl',
      'getUploadProgress',
      'getScanResult',
      'getOverallProgress',
      'getBookshelfAssessmentId',
      'getBackendErrorDialog'
    ]),
    ...mapGetters('search', [
      'getSearchResults',
      'getSelectedItemsIds',
      'getSelectedItemsCount',
      'getIsAllSelected',
    ]),
    // スキャン結果の査定価格の合計
    totalAppraisalPrice() {
      return this.getScanResult.reduce((acc, item) => {
        return acc + parseInt(item.appraisal_price, 10);
      }, 0);
    },
    progressMaskWidth() {
      // 100から進捗率を引いた値を返す
      const width = this.getOverallProgress > 0 ? 100 - this.getOverallProgress : 100;
      return 'width: ' + width + '%';
    },
    selectedItemsIds: {
      get() {
        return this.getSelectedItemsIds;
      },
      set(event) {
        this.updateSelectedItemsIds(event);
        this.updateIsAllSelected(event.length === this.getSearchResults.length);
      }
    },
    isAllToggle: {
      get() {
        return this.getIsAllSelected;
      },
      set(value) {
        this.updateIsAllSelected(value);
        this.selectAllItems();
      },
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    backendErrorDialog: {
      get() {
        return this.getBackendErrorDialog;
      },
      set(newValue) {
        this.mutateBackendErrorDialog(newValue);
      },
    },
  },
  watch: {
    // スキャンが完了したら
    getOverallProgress: {
      handler(newValue) {
        if (newValue === 100) {
          //　結果が0件の場合はダイアログを表示
          if (this.getScanResult.length === 0) {
            this.noResultsDialog = true;
          }
        }
      },
      immediate: true,
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .loader-scan {
    .v-skeleton-loader__image {
      height: 100px;
      width: 80px;
      margin: 10px;
    }
  }
  .scan-item {
    .v-input--selection-controls {
      margin-top: 4px;
    }
  }
  .v-dialog {
    box-shadow: none !important;
  }
  .login-modal {
    ul {
      padding-left: 0 !important;
    }
  }
}
.img-progress-mask {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(135deg, #FFF, #FFF 4px, transparent 4px, transparent 6px);
  z-index: 2;
  opacity: 0.7;
}
</style>