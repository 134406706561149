<template>
    <v-card tile elevation="0" class="flex-column" color="transparent">
        <v-card-title class="pa-0 mb-8 text-h5 font-weight-bold"><v-icon class="mr-2" color="#2C2C2C">mdi-magnify</v-icon>ジャンルから探す</v-card-title>
        <v-row class="ma-0">
            <v-col
                class="pa-0 mr-4 mb-4"
                v-for="(item, index) in items"
                :key="index"
                cols="auto">
                <v-chip
                    label
                    color="primary"
                    :href="createRequestUrl(item.genreId)">{{item.text}}</v-chip>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "search-by-genre",
    props: {
        items: {type: Array, default: null},
        conditions: {type: Array, default: null},
    },
    methods: {
        // キーワード検索用URL生成
        createRequestUrl: function (genreId) {
            let searchRequestUrl = '/search?conditions_category[]=' + genreId;
            if(this.conditions) {
                this.conditions.forEach((item) => {
                    searchRequestUrl = searchRequestUrl.concat('&' + item.name + '=', item.value);
                });
            }
            return searchRequestUrl;
        },
    }
}
</script>

<style scoped lang="scss">

</style>
