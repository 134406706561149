<template>
    <v-sheet 
    :width="$vuetify.breakpoint.mdAndUp ? '800' : 'inherit'">
        <v-sheet 
            class="ma-auto px-0 rounded-lg pb-6 mb-12 mb-md-8"
            :width="$vuetify.breakpoint.mdAndUp ? '702px' : '90%'" 
            min-height="100px" color="#f7f5eb">
            <v-img contain
            class="overflow-visible px-0"
            :aspect-ratio="$vuetify.breakpoint.mdAndUp ? 700/150 : 686/194"
            :src="$vuetify.breakpoint.mdAndUp ? (`${cdnUrl}` + '/assets/images/campaign/yurugengo-2306/cp_title_all_pc@2x.png') : (`${cdnUrl}` + '/assets/images/campaign/yurugengo-2306/cp_title_all_sp.png')"
            alt="創業16周年記念第一弾：クリエイター支援 with ゆる言語学ラジオ"
            :eager="true"
            />
            <v-img contain
            class="overflow-visible"
            :class="$vuetify.breakpoint.mdAndUp ? 'px-12 mx-auto mt-n8' : 'px-5'"
            :max-width="$vuetify.breakpoint.mdAndUp ? '80%' :''"
            :aspect-ratio="$vuetify.breakpoint.mdAndUp ? 1321/258 : 300/70"
            :src="$vuetify.breakpoint.mdAndUp ? (`${cdnUrl}` + switchImgsByDate['toptxtPc']) : (`${cdnUrl}` + switchImgsByDate['toptxtSp'])"
            alt="ソクフリを選択するだけで、査定金額の20%をポイント還元！"
            :eager="true"
            />
            <v-btn
                :ripple="false"
                href="/sell"
                width="327"
                height="48"
                elevation="0"
                class="d-flex white--text my-2 mx-auto"
                max-width="90%"
                style="background-color:#0095d9;"
                text
                >
                さっそく買取を申し込む
            </v-btn>
            <v-btn 
                :ripple="false"
                class="d-flex mx-auto pa-0 mt-6"
                @click="readMoreExpanded = !readMoreExpanded"
                v-if="!readMoreExpanded"
                text 
                :max-width="$vuetify.breakpoint.mdAndUp ? '320px' : '90%'"
                >
                <v-img :src="`${cdnUrl}` + '/assets/images/campaign/yurugengo-2306/check_txt_pc.png'"
                    contain
                    :max-width="$vuetify.breakpoint.mdAndUp ? '320px' : '256px'"
                    class="mx-auto"
                    alt="この取り組みについてもっと詳しく読む"
                />
            </v-btn>
            <Transition appear>
            <div class="expanded-contents-wrapper pb-6 mx-auto" v-if="readMoreExpanded" style="width:90%; text-align:center;">
                <v-img 
                class="mx-auto mt-11 mt-md-16"
                :max-width="$vuetify.breakpoint.mdAndUp ? '220px' : '64%'"
                :src="`${cdnUrl}` + '/assets/images/campaign/yurugengo-2306/vb_cheer@2x.png'"
                alt="バリューブックスのクリエイター支援"
                />
                <v-card-title class="justify-center text-center font-weight-bold">「学びのアスリート」を支援する</v-card-title>
                <v-img 
                class="mx-auto mt-2 mb-6"
                :max-width="$vuetify.breakpoint.mdAndUp ? '440px' : '90%'"
                :src="`${cdnUrl}` + '/assets/images/campaign/yurugengo-2306/ph1_yurugengo@.png'"
                alt="ゆる言語学ラジオ　アーティスト写真"
                />
                <p class="expanded-campaign-descriptions mx-auto">
                VALUE  BOOKS は、多くの本を手に取り、読書から得た力で活躍する「学びのアスリート」とも言えるクリエイターを支援しています。<br /><br />
                今回コラボレーションする「ゆる言語学ラジオ」は、水野太貴・堀元見のふたりが運営する YouTube ／ ポッドキャスト番組。
                「ゆるく楽しく言語の話をする」をコンセプトしつつ、トークテーマは多岐に渡り、コンテンツ内で紹介する本が何百冊も売れて重版されることも。
                </p>
                <div class="d-inline justify-center">
                    <a href="https://www.valuebooks.jp/bp/VS0058841426" target="_blank"
                    :max-width="$vuetify.breakpoint.mdAndUp ? '164px' : '34%'"
                    class="d-inline-block"
                    style="max-width:-webkit-fill-available;"
                    >
                    <v-img 
                    class="mx-auto my-6"
                    :max-width="$vuetify.breakpoint.mdAndUp ? '164px' : '34%'"
                    :src="`${cdnUrl}` + '/assets/images/campaign/yurugengo-2306/ph2_yurugengo.png'"
                    alt="『言語オタクが友だちに700日間語り続けて引きずり込んだ言語沼』書影"
                    />
                    </a>
                </div>
                <p class="expanded-campaign-descriptions mx-auto">
                今年の４月には、初の著書となる
                <a href="/bp/VS0058841426" target="_blank">『言語オタクが友だちに700日間語り続けて引きずり込んだ言語沼』</a>
                も刊行！ 
                VALUE BOOKS でも特典動画付きで販売しています。<br /><br />
                私たちはかねてより活動をサポートしてきましたが、「学びのアスリート」である彼らをより支援するため、
                <span class="font-weight-bold"
                        v-if="!isExtendedDay"
                        >
                        ６月中の買取のお申し込み１件につき「100円」を彼らに還元します。
                    </span>
                    <span class="font-weight-bold"
                        v-else
                        >
                        7/5までの買取のお申し込み１件につき「100円」を彼らに還元します。
                    </span>
                </p>

                <timer-display start='2023-06-06 00:00:00' end='2023-07-05 23:59:59'>
                    <v-card-title class="justify-center text-center font-weight-bold">VALUE BOOKS の倉庫、<br />ゆる言語学ラジオに案内してきた</v-card-title>
                    <v-img 
                    class="mx-auto my-6"
                    :max-width="$vuetify.breakpoint.mdAndUp ? '440px' : '90%'"
                    :src="`${cdnUrl}` + '/assets/images/campaign/yurugengo-2306/ph3_yurugengo@2x.png'"
                    alt="バリューブックス 倉庫写真"
                    />
                    <p class="expanded-campaign-descriptions mx-auto mb-6">
                    本キャンペーンの開催にあわせ、ゆる言語学ラジオとのコラボ動画を随時配信していきます！<br /><br />
                    バリューブックスの内部や活動を分かりやすく（かつ、おもしろく）コンテンツにしてくれていますので、ぜひあわせてご覧くださいませ！
                    </p>

                    <v-card class="d-flex ma-auto" flat justify-center
                        :width="$vuetify.breakpoint.mdAndUp ? '440px' : '90%'"
                        :height="$vuetify.breakpoint.mdAndUp ? '248px' : '200px'">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/rBK_3kz6AXw" 
                            title="100万冊の本がランダムに並ぶ倉庫。見学したら脳がバグった【バリューブックス見学】#235" 
                            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen
                        ></iframe>
                    </v-card>

                </timer-display>

                <v-btn
                href="/endpaper/11858/"
                width="327"
                max-width="90%"
                height="48"
                elevation="0"
                class="d-flex white--text my-2 mx-auto mt-6 mt-md-8 mb-4"
                style="background-color:#2c2c2c;"
                text
                >
                つづきを記事で読む
                </v-btn>
                <v-btn
                href="/sell"
                width="327"
                max-width="90%"
                height="48"
                elevation="0"
                class="d-flex white--text my-2 mx-auto"
                style="background-color:#0095d9;"
                text
                >
                買取キャンペーンを申し込む
                </v-btn>

            </div>
            </Transition>
        </v-sheet>
    </v-sheet>
</template>

<script>
import TimerDisplay from "../atoms/timer-display.vue";

export default {
    name: "campaign-advertise-on-top",
    components: {
        TimerDisplay,
    },
    data() {
        return {
        readMoreExpanded: false,
        switchingImgsConductDate: VueConfig.hideDefaultCampaignBanner.extendedTermStartDate,
        jstNow: new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000)),
        }
    },
    computed: {
        cdnUrl: function () {
            return VueConfig.cdn_url;
        },
        isExtendedDay () {
            if(this.jstNow < new Date(this.switchingImgsConductDate).getTime() ) {
                return false;
            } else {
                return true;
            }
        },
        switchImgsByDate () {// 7月に延長用バナーに切り替える
            if(!this.isExtendedDay) {
                return {
                    toptxtPc:'/assets/images/campaign/yurugengo-2306/txt_yuru_pc@2x.png', 
                    toptxtSp:'/assets/images/campaign/yurugengo-2306/txt_yuru_sp.png'
                };
            } else {
                return {
                    toptxtPc:'/assets/images/campaign/yurugengo-2306/txt_yuru_pc-ext0705.png', 
                    toptxtSp:'/assets/images/campaign/yurugengo-2306/txt_yuru_sp-ext0705.png'
                };
            }
        },
    }
}

</script>

<style scoped lang="scss">
.v-btn::before {
    background-color: transparent !important;
}
.expanded-campaign-descriptions {
    font-size: 14px;
    line-height:26px;
    max-width:90%;
    @media screen and (min-width: 960px) {
        max-width:440px;
    }
}
.v-enter-active,
.v-leave-active {
    transition: all 1s ease-in;
}

.v-enter-from, .v-enter {
    transform: translateY(-24px);
    opacity: 0;
}
.v-leave-to {
    transform: translateY(24px);
    opacity: 0;
}
</style>
