<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :width="$vuetify.breakpoint.smAndDown? '100%': '520px'"
    style="z-index: 10000"
  >
    <folder-preference-layout
      v-model="showDialog"
    >
      <template #title>フォルダ選択</template>
      <template #content>
        <v-container>
          <v-row v-if="!isFolderSelect" class="flex-column align-center my-3">
            <v-img max-width="30" max-height="30" class="mb-4" src="/assets/images/shelf/select_on.svg"></v-img>
            <span class="font-weight-black" style="color: #009098">{{getSelectedItemsCount}}冊の本が選択されています。</span>
          </v-row>
          <v-row class="flex-column align-center my-3">
            <slot name="title"></slot>
            <span v-if="failureCause" class="pl-0 pt-4 pb-0 text-caption red--text text--darken-4">{{
                failureCause
              }}</span>
          </v-row>
          <v-btn class="font-weight-black text-body-1 pl-1" style="color: #009098" text @click="overlayingFolderCreationDialog = true;">
            <v-icon large>mdi-plus</v-icon>新規作成
          </v-btn>
          <template v-if="getApiItems.folderList.length !== 0">
            <v-radio-group
              v-model="destinationFolderID"
              @change="updateApiItems({name: 'selectedCopyFolderId', value: $event});"
              row
            >
              <v-virtual-scroll
                :items="getApiItems.folderList"
                :item-height="$vuetify.breakpoint.smAndDown? '40px': '50px'"
                :height="$vuetify.breakpoint.smAndDown? '210px': '260px'"
              >
                <template v-slot:default="{ item }">
                  <v-radio
                    :label="item.folder_name + '（'+ item.shelf_items_count +'冊）'"
                    :value="item.folder_id"
                    class="mb-5 ml-3 mt-2"
                    :disabled="!isFolderSelect && item.folder_id === getApiItems.folderId"
                  ></v-radio>
                </template>
              </v-virtual-scroll>
            </v-radio-group>
          </template>
        </v-container>
      </template>
      <template #action>
        <v-sheet width="100%">
          <v-row>
            <v-col col="6" class="pb-0">
              <v-btn block outlined class="py-5" @click="showDialog = false">キャンセル</v-btn>
            </v-col>
            <v-col col="6" class="pb-0">
              <v-btn
                block
                color="grey darken-4"
                class="py-5 white--text"
                @click="addFolderItems"
                :disabled="isButtonDisabled"
              >
                <slot name="execButtonLabel"></slot>
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </template>
    </folder-preference-layout>
    <create-folder v-model="overlayingFolderCreationDialog" @copyFolderItems="addFolderItems" @showSnackbar="showSnackbarParent" :is-folder-select="true"></create-folder>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import FolderPreferenceLayout from "../../molecules/library/folder-preference-layout.vue";
import CreateFolder from "./create-folder.vue"

export default {
  name: "add-folder-item",
  components: {CreateFolder, FolderPreferenceLayout},
  props:{
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    isFolderSelect: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      destinationFolderID: null,
      overlayingFolderCreationDialog: false,
      failureCause: null,
      buttonDisabled: false,
    }
  },
  created: async function () {
    this.destinationFolderID = null;
    if(!this.$route.params.reuse) {
      await this.retrieveFolders();
    }
  },
  computed: {
    ...mapGetters('search', [
      'getSelectedItemsCount',
      'getSelectedItemsIds'
    ]),
    ...mapGetters('library', [
      'getApiItems'
      ]),
    ...mapGetters('bookshelfScan', [
      'getScanResult'
    ]),
    showDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    isButtonDisabled() {
      return this.destinationFolderID === null || this.buttonDisabled;
    }
  },
  methods: {
    ...mapActions('search', ['updateSelectedItemsIds', 'research', 'updateIsAllSelected']),
    ...mapActions('library', ['retrieveFolders', 'updateApiItems']),
    ...mapMutations('search', ['mutateIsItemSelectable']),

    async addFolderItems(){
      this.failureCause = null;
      this.buttonDisabled = true;
      const response = await axios.post('/ajax/shelf-items/folder/additems', {
        folderId: this.getApiItems.selectedCopyFolderId,
        seqList: this.addItems(),
      }).catch(e => {
        console.error("Error: " + e)
      })
      if (response && response.data.status) {
        this.showDialog = false;
        this.updateIsAllSelected(false);
        // アイテム選択状態を初期化
        this.mutateIsItemSelectable(false)
        this.updateSelectedItemsIds([]);

        // フォルダ数を更新
        await this.retrieveFolders();
        // スナックバーを表示
        this.$emit("showSnackbar", "add");

        this.$vuetify.goTo('header', {
          duration: 500,
          offset: 0,
          easing: 'easeInOutCubic',
        });
        this.buttonDisabled = false;
      } else if (response && response.data.status === false ) {
        this.errorHandler();
      } else {
        this.errorHandler();
      }
    },
    showSnackbarParent(status){
      this.$emit("showSnackbar", status);
    },
    errorHandler() {
      this.showDialog = false;
      this.$emit("showSnackbar", "warning");
      this.buttonDisabled = false;
    },
    addItems(){
      return this.getScanResult.map((item, index) => {
        return {
          spine: index + 1,
          vscatalogid: item.vs_catalog_id,
          asin: item.asin
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-radio--is-disabled {
    opacity: 0.7;
    .v-input--selection-controls__input {
      opacity: 0.3;
    }
  }
}
</style>