import Vue from 'vue'
import VueRouter from 'vue-router';
import BookshelfScan from "../components/organisms/bookshelf-scan.vue";
import VbTop from "../components/organisms/vb-top.vue";

Vue.use(VueRouter);

// baseタグのSPAのルート定義（order|mypage）
// コンポーネントからも参照できるようにインスタンスプロパティとして扱う
// インスタンス内からはthis.$routerBase OR $routerBaseでアクセス
const baseElement = document.getElementById('routerBase');
Vue.prototype.$routerBase = baseElement ? baseElement.getAttribute('href') : null;

export default new VueRouter({
    mode: 'history',
    relative: true, // route beseを動的に変更する
    routes: [
      {
        path: '/',
        component: VbTop
      },
      {
        path: '/spa/estimate/search',
        component: BookshelfScan,
      },
    ]
  }
)