<template>
  <v-lazy>
    <v-progress-circular
        indeterminate
        color="primary"
        v-if="loading"
    ></v-progress-circular>
    <div class="new-price" style="display: inline" v-else>査定価格：{{ this.estimatePrice | addCommaSeparator }}円</div>
  </v-lazy>
</template>

<script setup>
import { ref, watch } from 'vue'
import axios from 'axios'

const props = defineProps({
  id: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['old-price'])

const api = ref(VueConfig.estimate_url)
const listPrice = ref(0)
const estimatePrice = ref(0)
const oldPrice = ref(0)
const loading = ref(false)

const isDone = (status) => {
  return status == 2
}

const isError = (status) => {
  return status == 99
}

const estimate = async (id) => {
  loading.value = true

  if (!id) {
    listPrice.value = 0
    estimatePrice.value = 0
    oldPrice.value = 0
    loading.value = false
    return
  }

  const request_url = api.value + '/' + id

  try {
    const response = await axios.get(request_url)
    const result = response.data

    if (response.status === 200 && isDone(result.status)) {
      listPrice.value = result.list_price ?? 0
      estimatePrice.value = result.satei_price ?? 0
      oldPrice.value = result.classic_satei_price ?? 0
      loading.value = false
    } else if (isError(result.status)) {
      loading.value = false
    } else {
      // 査定中
      setTimeout(() => {
        estimate(id)
      }, 1000)
    }
  } catch (error) {
    loading.value = false
  }
}

watch(() => oldPrice.value, (price) => {
  emit('old-price', price)
})

watch(() => props.id, (val) => {
  estimate(val)
})

estimate(props.id)
</script>

<style scoped>
</style>
