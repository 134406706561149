<template>
  <v-card flat color="transparent">
    <v-row class="ma-0" justify="space-between" no-gutters>
      <v-col>
          <v-card-title class="pl-0 text-h5 font-weight-bold"><v-icon class="mr-2" color="#2C2C2C">mdi-book-open-variant</v-icon>{{ title }}</v-card-title>
      </v-col>
      <v-col>
        <template v-if="linkUrl" >
          <v-card-text class="text-right pr-8 text-subtitle-1"><a :href="linkUrl" class="d-inline-block ml-auto">{{ link }}</a></v-card-text>
        </template>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-sheet
          :max-width="$vuetify.breakpoint.smAndDown ? '95%' : '100%'"
          color="transparent"
      >
        <div ref="sliderA"
             class="pt-4 slide-content"
             :class="$vuetify.breakpoint.smAndDown ? 'mb-7' : 'mb-10'"
        >
          <template v-if="$vuetify.breakpoint.smAndUp">
            <v-img v-if="!isShowGoRightElement" @click="moveRight('sliderA')" class="slide-to-right" src="/assets/images/index/right-arrow.svg"></v-img>
            <v-img v-if="isShowGoRightElement" @click="moveElementTop('sliderA')" class="slide-to-right" src="/assets/images/index/double-right-arrow.svg"></v-img>
            <v-img v-if="isShowGoLeftElement" @click="moveLeft('sliderA')" class="slide-to-left" src="/assets/images/index/left-arrow.svg"></v-img>
          </template>
          <template v-for="(item,n) in items">
            <div class="slide-item">
                <v-scale-transition>
                  <a :href="'/endpaper/'+ item.id + '/'">
                    <v-card width="200" min-height="212" class="mr-4 mb-6">
                      <v-img height="110" width="200" :src=item.image :alt="item.title"></v-img>
                      <v-card-title class="py-3 px-4 omitted">{{ item.title }}</v-card-title>
                    </v-card>
                  </a>
                </v-scale-transition>
            </div>
          </template>
        </div>
      </v-sheet>
    </v-row>
  </v-card>
</template>

<script>
import VbItem from "../../molecules/vb-item.vue";

export default {
  name: "slide-item",
  components: {
    VbItem,
  },
  props: {
    title: {type: String, required: true, default: null},
    subtitle: {type: String, required: false, default: null},
    link: {type: String, required: false, default: "もっと見る"},
    linkUrl: {type: String, required: false, default: null},
    items: {type: Array, required: false, default: []},
  },
  data () {
    return {
      scrollPosition: 0,
      scrollWidth: 1,
      clientWidth: 0,
    }
  },
  methods: {
    next(ref) {
      this.$refs[ref].scrollOffset = 0;
    },
    moveRight(ref){
      const element = this.$refs[ref];
      const clientWidth = element.clientWidth;
      const nowScrollPotion = element.scrollLeft;

      element.scrollTo({left: nowScrollPotion + clientWidth*0.9, behavior: "smooth"});
    },
    moveLeft(ref){
      const element = this.$refs[ref];
      const clientWidth = element.clientWidth;
      const nowScrollPotion = element.scrollLeft;

      element.scrollTo({left: nowScrollPotion - clientWidth*0.9, behavior: "smooth"});
    },
    moveElementTop(ref){
      const element = this.$refs[ref];

      element.scrollTo({left: 0, behavior: "smooth"});
    }
  },
  computed: {
    hasNext() {
      return (ref) => {
        if(this.$refs[ref]) return this.$refs[ref].hasNext;
      }
    },
    isShowGoLeftElement() {
      return this.scrollPosition == 0 ? false : true;
    },
    isShowGoRightElement() {
      return this.scrollPosition + this.clientWidth >= this.scrollWidth
    }
  },
  mounted(){
    const element = this.$refs.sliderA;
    const self = this
    element.onscroll = function () {
      self.scrollPosition = element.scrollLeft;
      self.scrollWidth = element.scrollWidth;
      self.clientWidth = element.clientWidth
    }
  }
}
</script>

<style scoped lang="scss">
.slide-content {
  display:flex;
  overflow-x:auto;
  align-items: flex-end;
  .slide-to-left {
    overflow-x: auto;
    z-index: 1;
    top: 46%;
    left: 23px;
    right: auto;
    position: absolute;
  }
  .slide-to-right {
    overflow-x: auto;
    z-index: 1;
    top: 46%;
    left: auto;
    right: 44px;
    position: absolute;
  }
}
.slide-item {
  position: relative;
  flex: 0 0 auto;
}
::v-deep {
  .v-slide-group {
    &__prev,
    &__next,
    .slide-back-to-top {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
    &__prev {
      left: 80px;
    }
    &__prev,
    .slide-to-left {
      top: -5px;
    }
    &__next,
    .slide-back-to-top {
      right: 108px;
      top: -12px;
      margin: auto;
    }
    &__next,
    .slide-to-right{
      top: -3px;
    }
    &__prev--disabled,
    &__next--disabled {
      display: none;
    }
    &__content {
      align-items: end;
      padding-right: 108px;
    }
  }

  .omitted {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    line-height: 1.7em;
    height: calc(2.8em * 2);
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: .0178571429em;
    font-family: Roboto, sans-serif;
  }
}
</style>